<template>
    <div class="contact-index-page-main">
        <form class="contact-index-page-form formulary" @submit.prevent="onSubmit">
            <div class="contact-index-page-form__heading">
                <img :src="$assets.misc.contactIcon" />
                <h2>{{ $lang.pages.contacto.contact }}</h2>
            </div>
            <div id="title" class="wrap" @click="validations.title = false">
                <label class="title">{{ $lang.pages.contacto.title }}</label>
                <InputGeneric
                    v-model="formTitle"
                    bgcolor="light"
                    class="form-login__field"
                    type="text"
                    name="title"
                    :placeholder="$lang.pages.contacto.details"
                    :invalid="validations.title"
                >
                    {{ $lang.pages.contacto.required }}
                </InputGeneric>
            </div>
            <div id="subject" class="wrap relative" @click="validations.subject = false">
                <label class="title">{{ $lang.pages.contacto.affair }}</label>
                <div class="relative">
                    <button type="button" class="dropdown" @click="dropdownToggle(!showSubjets)">
                        <h3
                            class="title"
                            :class="validations.subject ? 'mb-3 border-red-500 ring-1 ring-red-500' : ''"
                        >
                            {{ selectedSubject ? selectedSubject.title : subjects[0].title }}
                            <img
                                class="icon-drop"
                                :class="showSubjets ? 'rotate-180 transition-all' : 'transition-all'"
                                :src="$assets.black.dropdown"
                                alt="dropdown"
                            />
                        </h3>

                        <div v-if="validations.subject" class="error">
                            <img :src="$assets.red.formError" alt="icon" />
                            <p>{{ $lang.pages.contacto.required }}</p>
                        </div>
                    </button>

                    <div v-if="showSubjets" ref="$subjectDropdown">
                        <DropdownSelect
                            :selected="selectedSubject || subjects[0]"
                            @newSelected="changeSubject"
                            classes="left-0 pt-1 w-full"
                            :options="subjects"
                            :invalid="validations.subject"
                        />
                    </div>
                </div>
            </div>
            <div id="name" class="wrap" @click="validations.name = false">
                <label class="title">{{ $lang.pages.contacto.name }}</label>
                <InputGeneric
                    v-model="formName"
                    bgcolor="light"
                    class="form-login__field"
                    type="text"
                    name="name"
                    :invalid="validations.name"
                    placeholder="José"
                >
                    {{ $lang.pages.contacto.required }}
                </InputGeneric>
            </div>
            <div id="email" class="wrap" @click="validations.email = false">
                <label class="title">{{ $lang.pages.contacto.email_address }}</label>
                <InputGeneric
                    v-model="formEmail"
                    bgcolor="light"
                    class="form-login__field"
                    type="email"
                    name="email"
                    :invalid="validations.email"
                    placeholder="jose@tucorreo.com"
                >
                    {{ $lang.pages.contacto.required }}
                </InputGeneric>
            </div>
            <!-- START DESCRIPCION (TODOS) -->
            <div id="message" class="wrap" @click="validations.message = false">
                <label class="title">{{ $lang.pages.contacto.message }}</label>

                <!-- contenttype="delta"
                type="text" -->
                <!-- :invalid="!!validations.message" -->
                <!-- v-model="form.message" -->
                <!--  -->
                <textarea
                    class="wrap__textarea"
                    name="message"
                    :placeholder="$lang.pages.contacto.remember"
                    datatype="delta"
                    v-model="message"
                ></textarea>

                <div v-if="validations.message" class="error mt-2">
                    <img :src="$assets.red.formError" alt="icon" />
                    <p>{{ $lang.pages.contacto.required }}</p>
                </div>
            </div>
            <!-- END DESCRIPCION (TODOS) -->

            <InputCheckbox id="termsAccept" v-model="termsAccept" class="mb-3">
                {{ $lang.components.formRegister.continue_accept_terms }}
                <NuxtLink no-prefetch :to="`/${$lang.routes.terms}`" rel="nofollow">{{
                    $lang.components.formRegister.community_rules
                }}</NuxtLink>
                {{ $lang.components.formRegister.continue_accept_terms2 }}
                <NuxtLink no-prefetch :to="`/${$lang.routes.privacy}`" rel="nofollow">{{
                    $lang.components.formRegister.privacy_policy
                }}</NuxtLink>
                {{ $lang.components.formRegister.continue_accept_terms3 }}
            </InputCheckbox>

            <button
                type="submit"
                class="button-input"
                :disabled="!isFormFullfilled"
                :class="
                    isFormFullfilled
                        ? 'bg-site-primary text-white transition hover:brightness-110'
                        : 'bg-violet-50 text-gray-800'
                "
            >
                <i class="fas fa-envelope fa-lg" :class="isFormFullfilled ? 'text-white' : 'text-gray-800'" />
                <p>{{ $lang.pages.contacto.send_message }}</p>
                <img v-if="loading" :src="$assets.white.loading" alt="icon" class="spin h-4 w-4" />
            </button>

            <NuxtTurnstile v-model="turnstileVerified" />
        </form>
    </div>
</template>

<script lang="ts" setup>
import type { ModelRef } from 'vue'
import { useRootStore } from '~/store/root'

import type { Api } from '~~/global'

const { $lang } = useNuxtApp()
const { setSiteNotification } = useRootStore()
const scrollTo = useScrollToComponent()
const Router = useRouter()
const {
    public: { origin },
} = useRuntimeConfig()
const Route = useRoute()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const turnstileVerified = ref()
watch(turnstileVerified, (val) => {
    console.log(val)
})
const { data: responseData, error } = await useAsyncData('contact-index-page-data', async () => {
    return await $fetch<Api.Responses.Info.Basic>(endpoints.info.contact.path, {
        headers: buildHeaders(),
        baseURL,
        method: 'GET',
    }).catch((e) => e.data || false)
})

if (error.value || !responseData.value || responseData.value.error === true) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const $subjectDropdown = ref()

onClickOutside($subjectDropdown, () => {
    dropdownToggle(false)
})

const message = defineModel('message') as ModelRef<string>

useSeoMeta({
    title: responseData.value.page.title,
    ogUrl: origin + Route.path,
    ogTitle: responseData.value.page.title,
    description: responseData.value.page.description,
    ogDescription: responseData.value.page.description,
    ogImageAlt: responseData.value.page.title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const termsAccept = ref(false)
const loading = ref(false)

const formName = ref('')
const formTitle = ref('')
const formEmail = ref('')

const validations = reactive({
    name: false as boolean | string,
    title: false as boolean | string,
    subject: false as boolean | string,
    email: false as boolean | string,
    message: false as boolean | string,
})

const subjects = ref([
    {
        id: 0,
        title: '¿Sobre qué asunto nos contactas?',
    },
    {
        id: 1,
        title: $lang.pages.contacto.share_offer,
    },
    {
        id: 2,
        title: $lang.pages.contacto.account,
    },
    {
        id: 3,
        title: $lang.pages.contacto.comercial,
    },
    {
        id: 4,
        title: $lang.pages.contacto.suggests,
    },
    {
        id: 5,
        title: $lang.pages.contacto.complaints_legal,
    },
    {
        id: 6,
        title: $lang.pages.contacto.others,
    },
])
const showSubjets = ref(false)
const selectedSubject = ref(null) as Ref<{ title: string; id: number } | null>

const isFormFullfilled = computed(() => {
    return (
        !!formName.value.length &&
        !!formTitle.value.length &&
        selectedSubject.value &&
        selectedSubject.value.id !== subjects.value[0].id &&
        !!formEmail.value.length &&
        !!message.value?.length
    )
})
const changeSubject = (subject: { title: string; id: number }) => {
    selectedSubject.value = subject

    dropdownToggle(false)
}
const RootStore = useRootStore()
const config = useRuntimeConfig()

const onSubmit = async () => {
    if (
        !turnstileVerified.value ||
        typeof turnstileVerified.value !== 'string' ||
        turnstileVerified.value.length === 0
    ) {
        RootStore.setSiteNotification({
            text: 'Compruebe que no es un robot',
            type: 'warning',
            duration: 8000,
        })
        return
    }
    if (termsAccept.value) {
        loading.value = true

        // Creamos el nuevo formData
        const bodyFormData = {
            name: formName.value,
            title: formTitle.value,
            subject: selectedSubject.value?.title || '',
            email: formEmail.value,
            message: message.value,
        }

        try {
            const response = await $fetch<
                Api.Responses.Info.SuccessContact | Api.Responses.Info.FailedContact
            >(endpoints.info.contact.path, {
                method: 'POST',
                body: bodyFormData,
                headers: buildHeaders(),
                baseURL,
            })

            if (response.feedback === 'params_validation_failed') {
                validations.name = response.data.name?.[0] || false
                validations.title = response.data.title?.[0] || false
                validations.subject = response.data.subject?.[0] || false
                validations.email = response.data.email?.[0] || false
                validations.message = response.data.message?.[0] || false

                try {
                    scrollTo(`#${Object.keys(response.data)[0]}`)
                } catch (error) {
                    scrollTo('#title')
                }
            } else if (response.feedback === 'mail_sent') {
                setSiteNotification({
                    duration: 5000,
                    text: $lang.pages.contacto.message_success,
                    type: 'success',
                    onClose: () => Router.push('/'),
                })

                formEmail.value = ''
                formName.value = ''
                formTitle.value = ''
                message.value = ''
                selectedSubject.value = null
                termsAccept.value = false
            }
            loading.value = false
        } catch (error) {
            setSiteNotification({
                duration: 5000,
                text: $lang.pages.contacto.message_error,
                type: 'error',
            })
            loading.value = false
        }
    } else {
        setSiteNotification({
            duration: 5000,
            text: 'Para continuar necesitas estar de acuerdo con las Reglas y políticas de privacidad.',
            type: 'error',
        })
    }
}

const dropdownToggle = (show: boolean) => {
    showSubjets.value = show
}
</script>

<style lang="postcss" scoped>
.contact-index-page {
    &-main {
        @apply mx-auto space-x-2 lg:container lg:my-4 lg:mt-4 lg:max-w-screen-xl;
    }
    &-form {
        @apply mx-auto mb-2 bg-white px-6 py-4 lg:w-3/4 lg:rounded-xl;
        &__heading {
            @apply mb-8 flex items-center gap-2;

            & h2 {
                @apply text-lg lg:text-[28px];
            }

            & img {
                @apply h-10 w-10;
            }
        }
        &__label {
            @apply font-medium;
        }
    }
}

.formulary {
    .wrap {
        @apply mb-4 flex flex-col space-y-1;

        &__textarea {
            @apply block h-32 w-full resize-none rounded-xl border-transparent bg-gray-100 py-3 text-sm text-gray-800 placeholder-gray-400 ring-site-primary-hover-light focus:border-site-primary-hover-light focus:bg-white focus:text-site-primary focus:ring-1 focus:ring-site-primary-hover-light lg:hover:ring-1;
        }

        .title {
            @apply flex gap-1 text-lg font-semibold text-gray-800;
            .optional {
                @apply text-sm font-medium text-site-primary;
            }
        }
    }
    .dropdown {
        @apply w-full;
        h3 {
            @apply flex w-full items-center justify-between rounded-xl border bg-[#F8F7FC] p-1 px-2 font-sans;
            .icon {
                @apply h-4;
            }
            .icon-drop {
                @apply ml-5 h-1.5;
            }
        }
    }
}

.form-input {
    @apply mb-2 mt-2 w-full rounded-lg border border-gray-300 bg-violet-50 text-gray-800 placeholder-gray-400;
}

.form-input.error {
    @apply border-red-400 placeholder-red-400;
}

.form-area-input {
    @apply mb-2 mt-2 w-full rounded-lg border border-gray-300 bg-violet-50 px-3 py-2 text-gray-800 placeholder-gray-400;
    max-height: 300px;
    min-height: 300px;
}

.form-area-input.error {
    @apply border-red-400 placeholder-red-400;
}

.button-input {
    @apply mb-4 mt-6 flex w-full items-center justify-center gap-2 rounded-xl p-4 sm:w-auto;
}

.error {
    @apply flex items-center space-x-1.5 text-sm text-red-500;
}
</style>
